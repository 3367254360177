<template>
  <div class="detail">
    <div class="container">
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">{{
            $t("details.material")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item style="fontweight: 700">{{
            $t("details.material1")
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 面包屑 -->

      <!-- three.js区域画布区域 -->
      <div class="detail-area">
        <div class="canvas-container">
          <canvas-area
            :materialMp4="materialMp4"
            :loading="loading"
            @clearLoading="clearLoading"
            v-loading="loading"
            :element-loading-text="$t('details.loading')"
          />
        </div>
        <div class="canvas-data">
          <canvas-data
            :materialData="materialData"
            @downloadShow="downloadShow"
            @purchaseAdd="purchaseAdd"
          />
        </div>
      </div>
      <!-- three.js区域画布区域 -->

      <!-- 模型说明区域 -->
      <div class="detail-specification">
        <div class="detail-specification-left">
          <el-row>
            <el-col :span="$i18n.locale === 'zh' ? 6 : 8">
              <span :class="$i18n.locale === 'zh' ? 'title' : 'titleEn'">{{ $i18n.locale === 'zh' ? materialData.title : materialData.titleEn | replaceTitle }}</span>
            </el-col>
            <el-col :span="16">
              <div class="right">
                <span class="watch">
                  <i class="el-icon-view" />
                  <span>{{ materialData.view }}</span>
                </span>
                <span class="download">
                  <i class="el-icon-download" />
                  <span>{{ materialData.purchase }}</span>
                </span>
                <collect
                  class="collect"
                  :item="materialData"
                  @confirmCollect="confirmCollect(materialData)"
                  @login="login"
                />
              </div>
            </el-col>
          </el-row>
          <el-row style="marginbottom: 0.9375rem">
            <el-col :span="$i18n.locale === 'zh' ? 6 : 8"
              >{{ $t("details.upload") }} :
              {{ materialData.createdAt | formaDate }}</el-col
            >
            <el-col :span="$i18n.locale === 'zh' ? 18 : 16"
              >{{ $t("details.description") }} :
              {{ $i18n.locale === 'zh' ? materialData.description : materialData.descriptionEn | replaceTitle }}</el-col
            >
          </el-row>
          <img
            :src="$i18n.locale === 'zh' ? 'https://holodata.prometh.xyz/webMetaTrade/%E7%BB%847.png':'https://holodata.prometh.xyz/webMetaTrade/officialWebsite/bottom-banner.png'"
            width="100%"
            class="specification-image"
            @click="$router.push({ path: '/plug-in', query: { goto: false } })"
          />
        </div>
        <div class="detail-specification-right">
          <div class="head-title" @click="$router.push('/merchants-page')">
            <img src="@/assets/shangjia.png" />
            <span>{{ $t("details.name") }}</span>
          </div>
          <div class="images">
            <el-image
              v-for="item in merchantsImages"
              :key="item.id"
              style="width: 44%; height: 130px"
              :src="item.coverImage"
              fit="cover"
              @click.stop="same(item)"
            />
          </div>
        </div>
      </div>
      <!-- 模型说明区域 -->
      <!-- 同类型 -->
      <div class="same-type">
        <el-col :span="3">
          <span class="title">{{ $t("details.same") }}</span>
        </el-col>
        <el-col :span="18">
          <div>
            <el-button type="text" class="refresh" @click="refreshList">
              <i class="el-icon-refresh" />
              <span>{{ $t("details.exchange") }}</span>
            </el-button>
          </div>
        </el-col>
        <el-row :gutter="20">
          <el-col v-for="item in samekind" :key="item.id" :span="6">
            <el-card :body-style="{ padding: '0rem' }">
              <el-image
                fit="cover"
                :src="item.coverImage"
                class="image"
                @click="same(item)"
                lazy
              />
              <div class="card-description">
                <div class="left">
                  <img
                    class="titleImg"
                    width="40px"
                    src="@/assets/shangjia.png"
                  />
                  <span>{{ $i18n.locale === 'zh' ? item.title: item.titleEn | replaceTitle }}</span>
                </div>
                <div class="right">
                  <span class="watch">
                    <i class="el-icon-view" />
                    <span>{{ item.view }}</span>
                  </span>
                  <collect
                    class="collect"
                    :item="item"
                    @confirmCollect="confirmCollect(item)"
                    @login="login"
                  />
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <!-- 同类型 -->
    </div>
    <!-- 下载弹窗 -->
    <download :show="showDownload" @downloadShow="downloadShow" />
    <!-- 登录弹窗 -->
    <login :dialog-login="dialogLogin" @login="login" />
  </div>
</template>
<script>
import collect from "@/components/collect";
import CanvasArea from "./components/canvas-area.vue";
import CanvasData from "./components/canvas-data.vue";
import download from "./components/download-dialog.vue"; 
import { materialDetail } from "@/api/material"; 
import { materialsList, checkMaterial } from "@/api/home"; 
import login from "@/components/login";
import { randomList, merchant, toggleError } from "@/utils/common";
import { getCollect } from "@/api/collect";
export default {
  name: "Detail",
  components: { CanvasArea, CanvasData, download, login, collect },
  data() {
    return {
      showDownload: false,
      materialData: {}, //详情页所有数据
      materialMp4: "", //mp4Url
      samekind: [],//筛选后的列表数据。
      loading: true,
      getCollectList: [],//收藏列表
      dialogLogin: false,
      merchantsImages: [],//筛选后的商家列表相片
      refresh: [],//所有列表数据
    };
  },
  created() {
    this.materialDetail();
  },
  methods: {
    //清除loading
    clearLoading() {
      this.loading = false;
    },
    //登录弹窗
    login() {
      this.dialogLogin = !this.dialogLogin;
    },
    //换一批
    refreshList() {
      this.samekind = randomList(this.refresh, this.materialData);
      this.filtrateCollectList();
    },
    //商家列表
    merchantList() {
      this.merchantsImages = merchant(this.refresh, this.materialData);
    },
    //获取收藏列表
    async getCollect() {
      const {
        data: { data },
      } = await getCollect();
      this.getCollectList = data;
    },
    //同类型跳转
    async same(item) {
      await checkMaterial(item.id);
      this.$router.push(`/detail/${item.id}`);
    },
    //同类型事件。
    async sameKindEvent() {
      const {
        data: { data },
      } = await materialsList();
      const arr = [data[0],data[1],data[4]]
      this.refresh = arr;
      this.$set(this.refresh, "collect", false);
      this.refreshList();
      this.merchantList();
      this.filtrateCollectList();
    },
    // 筛选收藏
    async filtrateCollectList() {
      if (this.$store.state.token) {
        this.getCollect();
        if (this.getCollectList !== []) {
          this.getCollectList.forEach((item) => {
            this.samekind.forEach((key) => {
              if (key.id == item.materialId) {
                key.collect = true;
                return;
              }
            });
          });
        }
      }
    },
    //收藏事件
    async confirmCollect(item) {
      if (item.collect) {
        item.collect = false;
        item.star--;
      } else {
        item.collect = true;
        item.star++;
      }
    },
    // 下载按钮弹窗事件
    downloadShow(boolean) {
      this.showDownload = boolean;
    },
    // 获取详情页信息
    async materialDetail() {
      try {
        const { data } = await materialDetail(this.$route.params.id);
        this.materialData = data;
        this.$set(this.materialData, "collect", false);
        this.materialMp4 = data.previewVideo;
        if (this.$store.state.token) {
          await this.getCollect();
          if (this.getCollectList !== []) {
            this.getCollectList.forEach((item) => {
              if (this.materialData.id == item.materialId) {
                this.materialData.collect = true;
                return;
              }
            });
          }
        }
        this.sameKindEvent();
      } catch (err) {
        toggleError("获取数据失败", "Failed to obtain data");
      }
    },
    // 下载自增按钮
    purchaseAdd() {
      this.materialData.purchase++;
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  .container {
    width: 1380px;
    margin: 90px auto 0;
    .breadcrumb {
      margin-top: 35px;
      margin-bottom: 15px;
      color: #fff;
      .el-breadcrumb {
        font-size: 16px;
      }
    }
    .detail-area {
      display: flex;
      height: 572px;
      margin-bottom: 15px;
      .canvas-container {
        position: relative;
        width: 1000px;
        height: 572px;
      }
      .canvas-data {
        box-sizing: border-box;
        height: 572px;
        width: 380px;
        padding: 26px 50px;
        background-color: #eee;
        overflow: hidden;
      }
    }
    .detail-specification {
      height: 220px;
      margin-bottom: 30px;
      display: flex;
      .detail-specification-left {
        flex: 2.65;
        margin-right: 47px;
        .specification-image {
          margin-top: 25px;
          cursor: pointer;
        }
        .title {
          line-height: 37px;
          font-size: 20px;
          font-weight: bold;
          margin-left: 0;
          color: #000000;
        }
        .titleEn {
          line-height: 37px;
          font-size: 18px;
          font-weight: bold;
          margin-left: 0;
          color: #000000;
        }
        .watch,
        .download {
          margin-right: 10px;
        }
        span {
          color: #2c3e50;
          font-size: 16px;
        }
      }
      span {
        margin-left: 2px;
        font-size: 16px;
      }
    }
    .detail-specification-right {
      flex: 1;
      background-color: #eeeeee;
      width: 380px;
      padding: 13px 10px;
      .head-title {
        cursor: pointer;
        span {
          margin-left: 10px;
        }
        img {
          width: 50px;
          height: 50px;
          vertical-align: middle;
        }
      }
      .images {
        cursor: pointer;
        .el-image {
          margin: 10px 10px 0 10px;
          border-radius: 5px;
        }
      }
    }
  }
  .same-type {
    height: 290px;
    margin-bottom: 50px;
    .title {
      line-height: 37px;
      font-size: 20px;
      font-weight: bold;
      margin-left: 0;
      color: #000000;
      margin-bottom: 10px;
    }
    .refresh {
      font-size: 16px;
      color: #2c3e50;
      cursor: pointer;
    }
    .el-row {
      position: static;
      .el-col {
        margin-bottom: 20px !important;
        .el-card {
          border: none;
          height: 250px;
        }
        .image {
          cursor: pointer;
          width: 100%;
          height: 200px;
          display: block;
        }
        .card-description {
          height: 50px;
          line-height: 50px;
          padding: 0 5px;
          .left {
            float: left;
            .titleImg {
              vertical-align: middle;
              // margin-right: 3px;
              transform: translateY(-2px);
            }
          }
          .right {
            float: right;
            box-sizing: border-box;
            .el-icon-view {
              margin-right: 2px;
              font-size: 18px;
              transform: translateY(1px);
            }

            .watch {
              margin-right: 10px;
            }
          }
        }
        span {
          font-size: 14px !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-radio-button__inner {
  border: none !important;
}
.is-active {
  .el-radio-button__inner {
    z-index: 1000;
    background-color: rgba(111, 22, 187, 1) !important;
  }
}
</style>
