/**
 * @author Neil Z. Shao, Hongyi Li
 * Prometheus 2021
 * https://www.prometh.xyz/
 */
import * as THREE from "three";
import { Mp4Loader } from "./Mp4Loader.js";
import { AudioManager } from "./AudioLoader.js";
const createMeshMaterialWithTexture = function (geometry, texture) {
  let material = new THREE.MeshBasicMaterial({});
  let mesh = new THREE.Mesh(geometry, material);
  mesh.scale.set(1, 1, 1);
  mesh.castShadow = true; // 需要投射阴影
  mesh.receiveShadow = true; // 需要接收阴影
  const b = Math.PI / 2;
  mesh.rotation.x = 2 * b;

  mesh.traverse(function (child) {
    if (child.isMesh) {
      child.material.map = texture;
      child.material.needsUpdate = true;
    }
  });
  return mesh;
};

class MeshMp4 {
  constructor(url) {
    this.root = new THREE.Object3D(); // 三维物体操作
    this.isOpened = false;
    this.ready = false;
    this.loop = false;
    this.isPlaying = false;
    this.isPlayOnce = false;
    this.frmIdx = 0;
    this.lastTime = new Date().getTime();
    this.displayMode = "mesh";
    this.transparent = false;
    this.resolution = 512;
    this.minNumReady = 30; //准备30张一组
    this.withAudio = false;
    this.mapShow = 1; //需不需要贴图
    this.maxNumLoaderBackward = 0;
    this.maxNumLoaderForward = 1;
    this.sumProgress = null;
    // mp4 loaders
    this.numMp4Loaders = 2;
    // msg
    this.onLoad = null;
    this.onPlayProgress = null;
    this.onCacheProgress = null;
    this.onError = null;

    if (url) load(url);

    const self = this;
    this.updater = setInterval(() => {
      self.update();
    }, 10);
  }

  // load info json
  load(url, onLoad, onPlayProgress, onCacheProgress, onError) {
    // single mp4
    if (url && url.endsWith(".mp4")) {
      const info = {
        fps: 22,
        mp4List: [{ length: 30, url: url }],
        numFrames: 30,
      };

      this.sumProgress = info.numFrames;
      this.open(info, onLoad, onPlayProgress, onCacheProgress, onError);
    }

    // mp4 list
    else {
      fetch(url + "info.json", {
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((info) => {
          info.mp4List.forEach(function (mp4Info) {
            mp4Info.url = url + mp4Info.url;
          });
          this.sumProgress = info.numFrames;
          // console.log("[MeshMp4] info =", info);
          this.open(info, onLoad, onPlayProgress, onCacheProgress, onError);
        })
        .catch((err) => {
          return Promise.resolve(err);
        });
    }
  }

  // open loaders
  open(info, onLoad, onPlayProgress, onCacheProgress, onError) {
    this.info = info;
    this.N = info.numFrames;

    this.onError = onError;
    this.onPlayProgress = onPlayProgress;
    this.onCacheProgress = onCacheProgress;
    // const audio_url = './data/test.mp3';
    if (this.withAudio) {
      this.audioManager = new AudioManager(info.url + "/zhaiwu.mp3");
    }

    let startIdx = 0;
    for (let i = 0; i < info.mp4List.length; ++i) {
      info.mp4List[i].startIdx = startIdx;
      startIdx += info.mp4List[i].length;
    }
    this.mp4Loaders = [];
    for (let i = 0; i < this.numMp4Loaders; ++i) {
      // for (let i = 0; i < this.info.mp4List.length; ++i) {
      this.mp4Loaders[i] = new Mp4Loader(info.mp4List[i]);
      this.mp4Loaders[i].createPlayer();
      this.mp4Loaders[i].mp4Idx = -1;
      this.mp4Loaders[i].loaderIdx = i;
    }
    this.preloadUrls(onLoad);
  }

  preloadUrls(onLoad) {
    this.preload = [];
    for (let i = 0; i < this.info.mp4List.length; ++i) {
      this.preload[i] = new Stream(this.info.mp4List[i].url);
    }

    const scope = this;
    for (let i = 0; i < scope.info.mp4List.length; ++i) {
      scope.preload[i].readAll(
        // complete
        function () {
          try {
            scope.preload[i].loaded = true;

            let allLoaded = true;
            for (let k = 0; k < scope.info.mp4List.length; ++k) {
              if (!scope.preload[k].loaded) allLoaded = false;
            }
            if (allLoaded) {
              for (let k = 0; k < scope.info.mp4List.length; ++k) {
                scope.preload[k] = null;
              }
              if (!scope.isOpened) {
                scope.updateLoaders();
                scope.isOpened = true;
                onLoad(scope.root);
              }
            }
          } catch (err) {
            return Promise.resolve(err);
          }
        }
      );
    }
  }

  changeMaterialMode(mode) {
    this.displayMode = mode;
  }

  // update current mesh
  updateCurrentMesh(map) {
    if (map !== undefined) {
      this.mapShow = map;
    }

    const mp4Idx = this.getMp4IdxForFrame(this.frmIdx);
    const subIdx = this.frmIdx - this.info.mp4List[mp4Idx].startIdx;
    const loaderIdx = this.getLoaderIdxForMp4(mp4Idx);
    this.mp4Loaders[loaderIdx].updateTexture(subIdx);
    const frameData = this.mp4Loaders[loaderIdx].datas[subIdx];
    const geometry = frameData.geometry;
    const texture = this.mp4Loaders[loaderIdx].texture;
    texture.minFilter = THREE.LinearFilter
    if (!geometry || !texture) {
      this.ready = false;
    }
    // replace mesh data
    if (this.mesh) {
      texture.needsUpdate = true;
      this.mesh.geometry = geometry;
      if (this.mapShow) {
        this.mesh.material = new THREE.MeshBasicMaterial({ color: 0xffffff });
        this.mesh.material.map = texture;
      } else {
        this.mesh.material.map = 0;
        this.mesh.material = new THREE.MeshLambertMaterial({ color: 0xffffff });
        this.mesh.geometry.computeVertexNormals();
      }
      this.mesh.material.needsUpdate = true;
      this.mesh.needsUpdate = true;
    } else {
      const mesh = createMeshMaterialWithTexture(
        geometry,
        texture,
        this.transparent,
        this.opacity
      );
      this.replaceDisplayMesh(mesh);
    }
  }
  replaceDisplayMesh(mesh) {
    for (var i = this.root.children.length - 1; i >= 0; i--) {
      this.root.remove(this.root.children[i]);
    }
    this.root.add(mesh);
    this.mesh = mesh;
  }
  // opacity
  setTransparent(transparent, opacity) {
    this.transparent = transparent;
    this.opacity = opacity;
    if (this.mesh && this.mesh.material) {
      this.mesh.material.transparent = transparent;
      this.mesh.material.opacity = opacity;
    }
  }
  // check ready
  checkReady() {
    if (this.ready) {
      if (!this.isFrameReady(this.frmIdx)) {
        this.ready = false;
        // console.log("[MeshMp4] ready -> not ready", this.frmIdx);
        const mp4Idx = this.getMp4IdxForFrame(this.frmIdx);
        const subIdx = this.frmIdx - this.info.mp4List[mp4Idx].startIdx;
        const loaderIdx = this.getLoaderIdxForMp4(mp4Idx);
        // console.log(
        //   "[MeshMp4] loader %d mp4 %d frame %d not ready",
        //   loaderIdx,
        //   mp4Idx,
        //   subIdx
        // );
      }
    } else {
      if (this.isBufferReady(this.frmIdx)) {
        this.ready = true;
        // console.log("[MeshMp4] not ready -> ready", this.frmIdx);
      }
    }
  }

  isFrameReady(frmIdx) {
    const mp4Idx = this.getMp4IdxForFrame(frmIdx); // 当前第几个mp4
    const subIdx = frmIdx - this.info.mp4List[mp4Idx].startIdx; // 当前帧
    const loaderIdx = this.getLoaderIdxForMp4(mp4Idx); //当前第几个loader
    // console.log(mp4Idx,subIdx,loaderIdx);
    return this.mp4Loaders[loaderIdx].isFrameReady(subIdx);
  }
  isBufferReady(frmIdx) {
    if (!this.isFrameReady(frmIdx)) return false;

    for (let i = 1; i < this.minNumReady; ++i) {
      const checkIdx = (frmIdx + i) % this.N;
      if (!this.isFrameReady(checkIdx)) return false;
    }
    return true;
  }
  getMp4IdxForFrame(frmIdx) {
    for (let i = 0; i < this.info.mp4List.length; ++i) {
      if (
        this.info.mp4List[i].startIdx + this.info.mp4List[i].length >
        frmIdx
      ) {
        return i;
      }
    }
    return 0;
  }
  // update loaders
  updateLoaders(verbose) {
    const mp4Idx = this.getMp4IdxForFrame(this.frmIdx);
    const subIdx = this.frmIdx - this.info.mp4List[mp4Idx].startIdx;
    this.preparePlayer(mp4Idx, subIdx);
    if (this.mp4Loaders.length > 1) {
      for (
        let nextIdx = mp4Idx + 1;
        nextIdx < this.info.mp4List.length;
        ++nextIdx
      ) {
        if (!this.preparePlayer(nextIdx, 0)) break;
      }
    }
    // dispose
    if (verbose) console.log("[MeshMp4] current loader", mp4Idx);
    const scope = this;
    const N = scope.info.mp4List.length;
    scope.mp4Loaders.forEach(function (item, index, object) {
      if (!item || item.mp4Idx < 0) return;
      const bwdGap = (mp4Idx - item.mp4Idx + N) % N;
      const fwdGap = (item.mp4Idx - mp4Idx + N) % N;
      if (verbose) console.log("update", index, bwdGap, fwdGap);
      if (
        bwdGap > scope.maxNumLoaderBackward &&
        fwdGap > scope.maxNumLoaderForward
      ) {
        // console.log("[MeshMp4] remove loader", index, "with mp4", item.mp4Idx);
        item.dispose();
        item.mp4Idx = -1;
      }
    });
  }
  getLoaderIdxForMp4(mp4Idx) {
    // find existing
    for (let i = 0; i < this.numMp4Loaders; ++i) {
      // for (let i = 0; i < this.info.mp4List.length; ++i) {
      if (this.mp4Loaders[i].mp4Idx == mp4Idx) return i;
    }
    // find new
    for (let i = 0; i < this.numMp4Loaders; ++i) {
      // for (let i = 0; i < this.info.mp4List.length; ++i) {
      if (this.mp4Loaders[i].mp4Idx == -1) return i;
    }
    return -1;
  }
  preparePlayer(mp4Idx, subIdx) {
    const loaderIdx = this.getLoaderIdxForMp4(mp4Idx);
    if (loaderIdx < 0) {
      return false;
    }
    if (this.mp4Loaders[loaderIdx].mp4Idx != mp4Idx) {
      this.mp4Loaders[loaderIdx].mp4Idx = mp4Idx;
      this.mp4Loaders[loaderIdx].open(this.info.mp4List[mp4Idx]);
    }
    this.mp4Loaders[loaderIdx].update(subIdx);
    return true;
  }
  // step
  gotoFrame(frmIdx) {
    this.frmIdx = frmIdx;
    this.isPlayOnce = true;
    this.update();
    // this.updateLoaders(true);
  }
  stepPrevious() {
    const a = (this.frmIdx - 1 + this.N) % this.N;
    this.gotoFrame(a);
  }
  stepNext(i) {
    if (i > this.N - 2) {
      this.gotoFrame(0);
    } else {
      this.gotoFrame((this.frmIdx + 1 + this.N) % this.N);
    }
  }
  // play
  play() {
    this.isPlaying = true;
  }
  pause() {
    this.isPlaying = false;
    // pause audio
    if (this.withAudio) this.audioManager.audioPause();
  }
  changeMaterialMode(resolution) {
    this.resolution = resolution;
    if (this.textureLoader) {
      // console.info('this.textureLoader.url',this.textureLoader.url,'length ',this.textureLoader.url.length);
      var totalLens = this.textureLoader.url.length;
      var urlSplit = this.textureLoader.url.split("/");
      var urlLength = urlSplit.length;
      var urlResolution = urlSplit[urlLength - 1];
      if (urlResolution.substring(0, 3) == "jpg") {
        var jpgDir =
          urlResolution.substring(0, 3) + "-" + this.resolution.toString();
        var newUrl =
          this.textureLoader.url.substring(
            0,
            totalLens - urlResolution.length
          ) + jpgDir;
        this.textureLoader.url = newUrl;
        console.log(".............New Url is ", this.textureLoader.url);
      }
    }
  }

  // update routine
  update() {
    // skip if not opened
    if (!this.isOpened) return;

    // skip if no playing
    if (!this.isPlaying && !this.isPlayOnce) {
      this.updateLoaders();
      return;
    }

    // skip update too fast
    const curTime = new Date().getTime();
    const fpsGap = 1000 / this.info.fps;
    if (curTime - this.lastTime < fpsGap) {
      this.updateLoaders();
      return;
    }
    // current ready -> goto next frame
    if (this.ready && this.isPlaying) {
      if (!this.loop && this.frmIdx >= this.N - 1) {
        this.pause = true;
        return;
      }

      // console.log('frmIdx', this.frmIdx, '->', (this.frmIdx + 1) % this.N);
      this.frmIdx = (this.frmIdx + 1) % this.N;
    }
    // check ready
    this.checkReady();
    if (!this.ready) {
      // if not ready pause audio
      if (this.withAudio) this.audioManager.audioPause();
      return;
    }

    // console.log('update');
    this.lastTime = curTime;

    // if ready and not playing update mesh
    if (this.withAudio) this.audioManager.audioPlay();
    if (this.onPlayProgress) {
      this.onPlayProgress(this.frmIdx);
    }
    this.updateCurrentMesh();
    this.isPlayOnce = false;
  }
}

export { MeshMp4 };
