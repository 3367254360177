<template>
  <!-- canvas区域右边数据列表和按钮。 -->
  <div class="canvas-data">
    <el-row>
      <el-col :span="$i18n.locale === 'zh' ? 12 : 9">{{
        $t("details.pri")
      }}</el-col>
      <el-col :span="$i18n.locale === 'zh' ? 6 : 12">
        <i class="el-icon-info" style="fontsize: 14px" />{{
          $t("details.explain")
        }}
      </el-col>
    </el-row>
    <div class="price">{{ materialData.price | filterMoney }}</div>
    <div class="download">
      <el-button
        type="warning"
        @click="download"
        v-loading="loading"
        round
        id="downloadNowVideo"
        >{{ $t("key.download") }}</el-button
      >
    </div>
    <el-divider />
    <p>{{ $t("details.format") }}: MP4 ({{ $t("details.format-explain") }})</p>
    <el-row class="canvas-data-time">
      <el-col :span="10"
        >{{ $t("details.time") }}:
        {{ materialData.duration | filterTime }}</el-col
      >
      <el-col :span="10"
        >{{ $t("details.size") }}: {{ materialData.size + " M" }}</el-col
      >
    </el-row>
    <el-row>
      <el-col :span="10"
        >{{ $t("details.face") }}: {{ materialData.numFaces }}</el-col
      >
      <el-col :span="10"
        >{{ $t("details.texture") }}: {{ materialData.atlasResolution }}</el-col
      >
    </el-row>
    <p>{{ $t("details.frames") }}: {{ materialData.fps }}</p>
    <el-row class="software">
      <el-col :span="$i18n.locale === 'zh' ? 5 : 10" class="software-title">
        <span>{{ $t("details.apply") }}:</span>
      </el-col>
      <el-col :span="3">
        <el-row>
          <i class="iconfont icon-unity"></i>
        </el-row>
        <span>unity</span>
      </el-col>
      <el-col :span="5">
        <el-row>
          <i class="iconfont icon-unrealengine"></i>
        </el-row>
        <span>ue4</span>
      </el-col>
    </el-row>
    <div class="down">
      <!-- <el-button
        type="warning"
        class="piug-in__download"
        @click="$router.push({ path: '/plug-in', query: { goto: true } })"
        round
        >{{ $t("details.plug") }}</el-button> -->
    </div>
    <login :dialogLogin="dialogLogin" @login="login" />
  </div>
</template>

<script>
import { buyMaterial, downloadLink, getDownloadList } from "@/api/material";
import { clickA, toggle, toggleMessage } from "@/utils/common";
import login from "@/components/login";
export default {
  name: "CanvasData",
  props: {
    materialData: {
      type: Object,
      required: true,
    },
  },
  components: {
    login,
  },
  data() {
    return {
      loading: false,
      dialogLogin: false,
    };
  },
  methods: {
    //login窗口
    login() {
      this.dialogLogin = false;
    },
    //下载自增
    purchaseAdd() {
      this.$emit("purchaseAdd");
    },
    // 立即下载逻辑
    async download() {
      this.loading = true;
      if (this.$store.state.token) {
        const {
          data: { data },
        } = await getDownloadList();
        const id = this.materialData.id;
        if (data.length > 0) {
          const list = data.map((item) => item.materialId);
          if (list.includes(id)) {
            const { data } = await downloadLink(this.materialData.id);
            await clickA(data.url, this.materialData.id);
            toggle("正在下载请稍后", "Downloading, please wait");
            this.purchaseAdd();
            this.$emit("downloadShow", true); //下载之后的窗口
          } else {
            this.buyMethod();
          }
        } else {
          this.buyMethod();
        }
      } else {
        this.dialogLogin = true;
        toggleMessage("请登录", "Please login");
      }
      this.loading = false;
    },
    //购买模型
    async buyMethod() {
      try {
        const id = { materialId: this.materialData.id };
        await buyMaterial(id);
        const { data } = await downloadLink(this.materialData.id);
        clickA(data.url, this.materialData.id);
        toggle("正在下载请稍后", "Downloading, please wait");
        // this.$emit("downloadShow", true);
      } catch (err) {
        this.loading = false;
        return Promise.resolve(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.canvas-data {
  background-color: pink;
  padding: 0 !important;
  .canvas-data-time {
    margin-bottom: 13px;
  }
  .price {
    text-align: center;
    transform: translateX(-50px);
    color: #7100d1;
    font-size: 50px;
    margin: 40px 0;
  }
  .download,
  .down {
    text-align: center;
    transform: translateX(-50px);
    .el-button--warning {
      overflow: hidden;

      width: 180px;
      height: 45px;
      font-size: 16px;
      border-radius: 22px;
      border: none;
      background-color: #ffc322;
      margin-bottom: 6px;
    }
  }
  .down {
    .piug-in__download {
      color: #ffc322;
      margin-top: 20px;
      background-color: #fff;
    }
  }
  .el-divider--horizontal {
    width: 75%;
    background-color: #c8c8c8;
  }
  .software {
    height: 53px;
    .software-title {
      line-height: 53px;
    }
    .iconfont {
      font-size: 30px;
    }
  }
}
</style>
