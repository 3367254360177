import request from "@/utils/request";

/**
 *获取收藏列表
 *
 */
export const getCollect = () => {
  return request({
    url: "/mlib/favorites",
  });
};
/**
 *收藏
 *
 */
export const addCollect = (data) => {
  return request({
    url: "/mlib/favorite",
    method: "POST",
    data,
  });
};
/**
 *取消收藏
 *
 */
export const deleteCollect = (id) => {
  return request({
    url: `/mlib/favorite/${id}`,
    method: "DELETE",
  });
};
