<template>
<!-- 购买弹窗 -->
  <el-dialog
    :visible="show"
    width="35%"
    center
    top="30vh"
    class="download-material-dialog"
    @close="$emit('downloadShow', false)"
  >
    <p class="title">
      {{ $t("details.title") }}
      <!-- <span style="color: #ffc322">联系客服</span> 解决 -->
    </p>
    <el-row type="flex" justify="center" align="center">
      <el-col :span="5">
        <i class="iconfont icon-rentijiance"></i>
        <div class="text">{{ $t("details.material2") }}</div>
      </el-col>
      <el-col :span="3">
        <i class="el-icon-arrow-right" />
      </el-col>
      <el-col :span="5">
        <i class="iconfont icon-chajian1"></i>
        <div class="text">{{ $t("plugIn.use-btn2") }}</div>
      </el-col>
      <el-col :span="3">
        <i class="el-icon-arrow-right" />
      </el-col>
      <el-col :span="5">
        <i class="iconfont icon-icon-safe-light"></i>
        <div class="text">{{ $t("plugIn.use-btn4") }}</div>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="$router.push('/help-center')"
        class="dialog-footer-btn1"
        >{{ $t("plugIn.use-btn5") }}</el-button
      >
      <el-button
        type="primary"
        @click="$router.push({ path: '/plug-in', query: { goto: true } })"
        class="dialog-footer-btn2"
        >{{ $t("plugIn.use-btn2") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.download-material-dialog {
  .el-row {
    text-align: center;
    margin-top: 40px;
    .el-col {
      position: relative;
      .iconfont {
        font-size: 50px;
        color: #7972f7;
      }
      .text {
        margin-top: 10px;
        color: #000;
      }
      .el-icon-arrow-right {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #7972f7;
        font-size: 27px;
        font-weight: 900;
      }
    }
  }
  .title {
    text-align: center;
    font-size: 16px;
    color: #000;
  }
  .dialog-footer {
    .dialog-footer-btn1,
    .dialog-footer-btn2 {
      width: 203px;
      height: 57px;
      border-radius: 28px;
      border: none;
      background-color: #ffc322;
      color: #000;
      font-size: 16px;
    }
    .dialog-footer-btn1 {
      background-color: transparent;
      border: 2px solid #ffc322;
    }
  }
}
</style>

<style lang="scss">
.download-material-dialog {
  .el-dialog {
    border-radius: 5px;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__footer {
      padding-bottom: 30px;
    }
  }
}
</style>
