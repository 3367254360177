import request from "@/utils/request";
/**
 *获取列表
 */
export const materialsList = () => {
  return request({
    url: "mlib/materials",
  });
};
/**
 *查看详情。查看数量自增。
 */
export const checkMaterial = (id) => {
  return request({
    url: `mlib/stats/view/${id}`,
    method: "POST",
  });
};
