<template>
  <div ref="container" class="preview-container">
    <!-- 按钮区域 -->
    <div class="describe">
      <i class="iconfont icon-gantanhao-quan"></i>{{ $t("details.content") }}
    </div>
    <div class="full-screen">
      <el-button
        class="full-screen-btn"
        circle
        @click="screenfullBtn"
        @keyup.esc="screenfullBtn"
      >
        <i class="iconfont icon-fangda"></i>
      </el-button>
    </div>
    <div class="slide">
      <el-radio-group v-model="radio1" @change="removeMap" class="slide-btn">
        <el-radio-button :label="$t('details.def')" />
        <el-radio-button :label="$t('details.white')" />
      </el-radio-group>
    </div>
    <div class="progress">
      <el-slider
        class="slider"
        @change="sliderClick(currentProgress)"
        v-model="currentProgress"
        :max="sumProgress"
      />
    </div>
    <div class="play">
      <el-button
        type="primary"
        icon="el-icon-caret-left"
        circle
        @click="stepPrevious"
      />
      <el-button
        v-if="playShow"
        type="success"
        class="play-btn"
        icon="el-icon-video-play"
        circle
        @click="play"
      />
      <el-button
        v-else
        type="success"
        class="play-btn"
        icon="el-icon-video-pause"
        circle
        @click="pause"
      />
      <el-button
        type="info"
        icon="el-icon-caret-right"
        circle
        @click="stepNext"
      />
    </div>
    <div class="select">
      <!-- <div>
        <span>场景</span>
        <el-button type="primary" circle>
          <i class="iconfont icon-quanjing"></i>
        </el-button>
      </div>-->
      <div>
        <span>{{ $t("details.rotate") }}</span>
        <el-button type="success" circle @click.stop="rotate">
          <i
            class="iconfont"
            :class="
              rotateShow ? 'icon-igw-l-refresh' : 'icon-igw-l-refresh-disable'
            "
          ></i>
        </el-button>
      </div>
      <!-- <div>
                <span>节选</span>
                <el-button type="info" icon="el-icon-message" circle></el-button>
      </div>-->
      <!-- </div> -->
    </div>
    <!-- 按钮区域 -->
  </div>
</template>
<script>
import * as THREE from "three";
import { MeshMp4 } from "@/MeshMp4/MeshMp4.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  name: "App",
  data() {
    return {
      mesh4D: null, // mesh4Dclass类的
      scene: null, // 场景
      renderer: {}, // 渲染器
      camera: null, // 相机
      sumProgress: 0, // 总进度
      currentProgress: 0, // 当前进度条
      playShow: true, // 播放图标
      radio1: "默认", // 白膜单选框
      screenFull: false, // 全屏判断
      rotateShow: true, // 旋转判断
      animation1: null, // 用来取消动画的
      animation2: null, // 用来取消动画的
      controls: "", //控件
    };
  },
  props: {
    materialMp4: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    //判断当前进度，进行循环播放
    currentProgress(newVal) {
      if (newVal == this.sumProgress) {
        this.mesh4D.gotoFrame(0);
        this.play();
      }
    },
    //数据更新。初始化场景
    materialMp4(newVal) {
      if (newVal) {
        this.initScene();
      }
    },
    //语言更新。判断白模按钮逻辑。
    "$i18n.locale"(newVal) {
      if (newVal === "en") {
        if (this.radio1 === "默认") {
          this.radio1 = "Textured";
        } else {
          this.radio1 = "Normal";
        }
      } else {
        if (this.radio1 === "Textured") {
          this.radio1 = "默认";
        } else {
          this.radio1 = "白模";
        }
      }
    },
  },
  mounted() {
    // 监听全屏事件。进行切换控制。
    document.addEventListener("fullscreenchange", () => {
      this.screenFull = !this.screenFull;
    });
    if (this.$i18n.locale === "en") {
      this.radio1 = "Textured";
    } else {
      this.radio1 = "默认";
    }
  },
  destroyed() {
    this.removeScene();
  },
  methods: {
    // canvas区域滑块数据改变触发
    sliderClick(num) {
      this.mesh4D.gotoFrame(num);
      // this.play();
    },
    // 旋转按钮函数。
    rotate() {
      if (this.rotateShow) {
        cancelAnimationFrame(this.animation1);
        requestAnimationFrame(this.animation);
        this.rotateShow = false;
      } else {
        cancelAnimationFrame(this.animation2);
        requestAnimationFrame(this.animate);
        this.rotateShow = true;
      }
    },
    // 全屏按钮函数。
    screenfullBtn() {
      if (this.screenFull) {
        document.exitFullscreen();
        this.$refs.container.children[
          this.$refs.container.children.length - 1
        ].style.width = "100%";
        this.$refs.container.children[
          this.$refs.container.children.length - 1
        ].style.height = "100%";
      } else {
        this.$refs.container.requestFullscreen();
        this.$refs.container.children[
          this.$refs.container.children.length - 1
        ].style.width = "100%";
        this.$refs.container.children[
          this.$refs.container.children.length - 1
        ].style.height = "100%";
      }
    },
    //删除贴图
    removeMap() {
      if (this.radio1 !== "默认" && this.radio1 !== "Textured") {
        this.mesh4D.updateCurrentMesh(0);
      } else {
        this.mesh4D.updateCurrentMesh(1);
      }
    },
    // 删除three.js数据
    removeScene() {
      this.mesh4D = {};
      this.scene = {};
      this.renderer = {};
      this.camera = {};
    },
    // 初始化网格模型。
    async initLoader() {
      this.mesh4D = new MeshMp4();
      await this.mesh4D.load(
        this.materialMp4,
        (root) => {
          this.scene.add(root);
          this.playShow = false;
          this.mesh4D.play();
          this.sumProgress = this.mesh4D.sumProgress - 1;
        },
        (currentProgress) => {
          this.currentProgress = currentProgress;
          this.$emit("clearLoading");
        }
      );
    },
    // 上一帧
    stepPrevious() {
      this.mesh4D.stepPrevious();
    },
    // 下一帧
    stepNext() {
      this.mesh4D.stepNext(this.currentProgress);
    },
    // 播放
    play() {
      this.playShow = false;
      this.mesh4D.play();
    },
    // 暂停
    pause() {
      this.playShow = true;
      this.mesh4D.pause();
    },
    // 初始化场景
    async initScene() {
      const height = this.$refs.container.clientHeight;
      const width = this.$refs.container.clientWidth;
      this.camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 300);
      this.camera.position.set(0, 1, 4);
      this.scene = new THREE.Scene(); // 创建场景
      this.scene.background = new THREE.Color(0x443333);
      this.scene.fog = new THREE.Fog(0x443333, 6, 10);
      // 地板
      const plane = new THREE.Mesh(
        new THREE.PlaneGeometry(100, 100),
        new THREE.MeshPhongMaterial({ color: 0xcccccc, specular: 0x101010 })
      );
      this.scene.position.set(0, -0.7, 0);
      plane.rotation.x = -Math.PI / 2;
      plane.receiveShadow = true;
      this.scene.add(plane);
      // 灯
      const hemiLight = new THREE.HemisphereLight(0x999999, 0x111122);
      this.scene.add(hemiLight);
      const spotLight = new THREE.SpotLight();
      spotLight.angle = Math.PI / 10;
      spotLight.penumbra = 1;
      spotLight.castShadow = true;
      spotLight.position.set(5, 5, 5);
      this.scene.add(spotLight); // 创建灯
      await this.initLoader(); // 网格模型
      this.renderer = new THREE.WebGLRenderer({
        antialias: true, //抗锯齿
        alpha: true,
      }); // 渲染器
      this.renderer.setSize(width, height);
      this.renderer.shadowMap.enabled = true;
      document
        .querySelector(".preview-container")
        .appendChild(this.renderer.domElement); // 需要渲染的canvas放入到dom里。
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.maxDistance = 9; //最大控制滚轮范围
      this.controls.minDistance = 1; //最小控制滚轮范围
      this.controls.zoomSpeed = 2; //控制滚轮速度
      this.animate();
    },
    // 旋转动画
    animate() {
      try {
        this.renderer.render(this.scene, this.camera);
        this.mesh4D.mesh.rotateY(0.01);
      } catch (err) {
        if (!this.mesh4D) {
          this.mesh4D.mesh.rotateY(0.01);
        }
      }
      this.animation1 = requestAnimationFrame(this.animate); // 浏览器执行动画。返回值时用于删除动画的id。
    },
    // 旋转静止
    animation() {
      this.renderer.render(this.scene, this.camera);
      this.animation2 = requestAnimationFrame(this.animation);
    },
  },
}
</script>
<style scoped lang="scss">
.preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ccc;

  .iconfont {
    font-size: 20px;
  }
  .describe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    line-height: 70px;
    padding-left: 20px;
    color: #fff;
    background-image: linear-gradient(
      rgba(111, 22, 187, 0.5),
      rgba(111, 22, 187, 0)
    );
    .icon-gantanhao-quan {
      display: inline-block;
      font-size: 18px;
      margin-right: 5px;
      transform: translateY(1px);
    }
  }
  .full-screen {
    position: absolute;
    bottom: 20px;
    right: 60px;
    width: 50px;
    height: 50px;
    .full-screen-btn {
      // font-size: 20px;
      border: none;
      background-color: rgba(111, 22, 187, 1);
      color: #fff;
    }
  }
  .slide {
    position: absolute;
    top: 20px;
    right: 60px;
    // width: 200px;
    height: 40px;
    z-index: 10;

    .slide-btn {
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;
      .el-radio-button {
        border-radius: 20px;
        border: none;
        overflow: hidden;
      }
    }
  }
  .progress {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: 600px;
    .progress {
      position: absolute;
      left: 50%;
      bottom: 34px;
      z-index: -1;
      transform: translateX(-50%);
    }
  }
  .play {
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    width: 350px;
    height: 55px;
    display: flex;
    justify-content: space-around;
    .el-button {
      width: 50px;
      height: 50px;
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: 35px !important;
    }
    .play-btn {
      font-size: 35px !important;
    }
  }
  .select {
    position: absolute;
    left: 0;
    bottom: 50px;
    width: 120px;
    height: auto;
    background-image: linear-gradient(
      to right,
      rgba(111, 22, 187, 1),
      rgba(111, 22, 187, 0) 80%
    );
    div {
      margin: 20px 0 20px 10px;
      span {
        margin-right: 8px;
        color: #fff;
      }
      .el-button {
        background-color: #a73fff;
        border: none;
        font-size: 20px;
      }
    }
  }
}
</style>
<style lang="scss">
.preview-container {
  .el-loading-mask {
    z-index: 1800;
  }
}
</style>
