const dict = {
  // 其中 65 - 70， 97 - 102 (a - f) 不区分大小写
  48: "0",
  49: "1",
  50: "2",
  51: "3",
  52: "4",
  53: "5",
  54: "6",
  55: "7",
  56: "8",
  57: "9",

  65: "A", // A
  66: "B", // B
  67: "C", // C
  68: "D", // D
  69: "E", // E
  70: "F", // F

  97: "a", // a
  98: "b", // b
  99: "c", // c
  100: "d", // d
  101: "e", // e
  102: "f", // f
  103: "g", // g
  104: "h", // h
  105: "i", // i
  106: "j", // j
  107: "k", // k
  108: "l", // l
  109: "m", // m
  110: "n", // n
  111: "o", // o
  112: "p", // p
  113: "q", // q
  114: "r", // r
  115: "s", // s
  116: "t", // t
  117: "u", // u
  118: "v", // v
  119: "w", // w
  120: "x", // x
  121: "y", // y
  122: "z", // z
};

const intFromArray = function (array) {
  const s1 = "0x" + array[0] + array[1];
  const s2 = "0x" + array[2] + array[3];
  const s3 = "0x" + array[4] + array[5];
  const s4 = "0x" + array[6] + array[7];

  const n1 = parseInt(s1);
  const n2 = parseInt(s2);
  const n3 = parseInt(s3);
  const n4 = parseInt(s4);
  const value = n4 * 256 * 256 * 256 + n3 * 256 * 256 + n2 * 256 + n1;
  return value;
};

const charFromArray = function (array) {
  const str = "0x" + array[0] + array[1];
  const value = parseInt(str);
  return value;
};

// uint8arrry to char array
const hex2str = function (array) {
  const str = [];
  for (let i = 0; i < array.length; i++) {
    // 从 buffer 里取出元素，hex 就变成 int
    const k = array[i];
    // 将 int 转成对应的 str
    const n = dict[k];
    str.push(n);
  }
  return str;
};

const parseLengh = function (len_buffer) {
  const str = hex2str(len_buffer);
  const length = intFromArray(str);
  return length;
};

const parseTag = function (tag_buffer) {
  const str = hex2str(tag_buffer);
  const tag = str.join("");
  return tag;
};

const parseHexChar = function (array) {
  const bytes = hex2str(array);
  return charFromArray(bytes);
};

const arrayEqual = function (arr1, arr2) {
  if (arr1.length != arr2.length) {
    return false;
  }
  for (var i = 0; i < arr1.length; ++i) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

const findMultiBytesIndex = function (bytes, tag, fromIndex) {
  var index = bytes.indexOf(tag[0], fromIndex);
  if (tag.length === 1 || index === -1) {
    // Not found or no other elements to check
    return index;
  }

  for (var i = index, j = 0; j < tag.length && i < bytes.length; i++, j++) {
    if (bytes[i] !== tag[j]) {
      return findMultiBytesIndex(bytes, tag, index + 1);
    }
  }

  return i === index + tag.length ? index : -1;
};

export {
  intFromArray,
  charFromArray,
  hex2str,
  parseLengh,
  parseTag,
  parseHexChar,
  arrayEqual,
  findMultiBytesIndex,
};
