/**
 * @author Neil Z. Shao, Hongyi Li
 * Prometheus 2021
 * https://www.prometh.xyz/
 */
import * as THREE from "three";
import * as HexParser from "./HexParser.js";
import { Base64Binary } from "./base64-binary.js";
import { DRACOLoader } from "./DRACOLoader.js";
class FrameData {
  constructor(frmIdx) {
    this.idx = frmIdx;
    this.loading = false;
    this.loaded = false;

    this.textureData = null;
    this.geometry = null;
  }

  checkLoaded() {
    if (this.textureData && this.geometry) {
      // console.log('[FrameData] loaded', this.idx);
      this.loading = false;
      this.loaded = true;
      return true;
    }
    return false;
  }

  dispose() {
    // console.log('[FrameData] dispose', this.idx);
    this.loaded = false;
    this.loading = false;

    if (this.textureData) {
      this.textureData.length = 0;
      this.textureData = null;
    }

    if (this.geometry) this.geometry.dispose();
  }
}

class Mp4Loader {
  constructor() {
    this.player = null;
    this.playerLoaded = false;
    this.dracoLoader = null;

    this.datas = [];
    this.maxNumBufferForward = 90;
    this.maxNumBufferBackward = 5;
    this.texture = null;

    this.loaderIdx = -1;
    this.verbose = true;

    this.defaultAttributeIDs = {
      position: "POSITION",
      normal: "NORMAL",
      color: "COLOR",
      uv: "TEX_COORD",
    };
    this.defaultAttributeTypes = {
      position: "Float32Array",
      normal: "Float32Array",
      color: "Float32Array",
      uv: "Float32Array",
    };
  }

  // update loading based on current frame index
  update(frmIdx) {
    this.removeUnusedData(frmIdx);
    this.updateLoader(frmIdx);
  }

  createPlayer(canvasObj) {
    if (!this.player) {
      // create MP4Player with webgl canvas
      // - useWorkers: faster
      // - render: no use
      // - webgl: faster than RGB
      const useWorkers = true;
      const render = false;
      const webgl = true;
      this.player = new MP4Player(useWorkers, webgl, render, canvasObj);

      // avc is Player
      // disable inner render
      this.player.avc.render = false;
      this.player.avc.reuseMemory = false;
      this.canvas = this.player.canvas;

      // onLoad frame
      this.player.avc.onPictureDecoded = function (
        buffer,
        width,
        height,
        infos
      ) {
        const frmIdx = infos[0].frmIdx;
        this.player.frmIdx = frmIdx;
        // console.log('[Mp4Loader] frame', frmIdx, infos);
        this.datas[frmIdx].textureData = {
          data: buffer.slice(),
          width: width,
          height: height,
        };
        if (this.datas[frmIdx].checkLoaded() && this.verbose) {
          // console.log(
          //   "[Mp4Loader]",
          //   this.loaderIdx,
          //   "loaded",
          //   frmIdx,
          //   "(",
          //   frmIdx + this.mp4Info.startIdx,
          //   ")"
          // );
        }
      }.bind(this);

      // onLoad SEI
      this.player.avc.onLoadSEI = function (frmIdx, bytes, index) {
        this.decodeSEI(frmIdx, bytes, index);
      }.bind(this);
    }
  }

  open(mp4Info) {
    this.mp4Info = mp4Info;
    const url = mp4Info.url;

    // start parsing
    this.player.readAll(
      new Stream(url),
      function () {
        // console.log("[Mp4Loader] player loaded");
        this.playerLoaded = true;
        this.player.decodeTarget = this.maxNumBufferForward;
        this.player.isPlaying = true;
        this.player.onLoading = function (frmIdx) {
          // console.log('[Mp4Loader] loading', frmIdx);
          this.datas[frmIdx] = new FrameData(frmIdx);
          this.datas[frmIdx].loading = true;
          this.datas[frmIdx].loaded = false;
        }.bind(this);
        this.player.play();
      }.bind(this)
    );
  }
  // remove data in buffer thats expired
  removeUnusedData(frmIdx) {
    const scope = this;
    scope.datas.forEach(function (item, index, object) {
      if (!item) return;

      const bwdGap = (frmIdx - item.idx + scope.N) % scope.N;

      const fwdGap = (item.idx - frmIdx + scope.N) % scope.N;

      if (
        bwdGap > scope.maxNumBufferBackward &&
        fwdGap > scope.maxNumBufferForward
      ) {
        if (scope.verbose) console.log("[Mp4Loader] remove data", item.idx);
        item.dispose();
        scope.datas[item.idx] = null;
      }
    });
  }

  dispose() {
    // remove datas
    const scope = this;
    scope.datas.forEach(function (item, index, object) {
      if (!item) return;

      item.dispose();
      scope.datas[item.idx] = null;
    });

    // // remove player
    // if (this.player) {
    //     this.playerLoaded = false;
    //     // this.player.dispose();
    //     this.dracoLoader = null;
    //     this.texture.dispose();
    //     this.player = null;
    // }
    // quit player
    this.player.quit = true;
  }

  // current loading
  getCurrentLoading() {
    let count = 0;
    for (let i = 0; i < this.N; ++i) {
      if (this.datas[i] && this.datas[i].loading) count++;
    }
    return count;
  }

  // current loaded
  getCurrentLoaded() {
    let count = 0;
    for (let i = 0; i < this.N; ++i) {
      if (this.datas[i] && this.datas[i].loaded) count++;
    }
    return count;
  }

  // update loader to load more
  updateLoader(frmIdx) {
    // check restart player
    // console.log(frmIdx, this.player.frmIdx);

    // update decode target
    if (this.playerLoaded) {
      this.player.decodeTarget = frmIdx + this.maxNumBufferForward;
      this.player.isPlaying = true;
    }
  }

  isFrameReady(frmIdx) {
    // console.log('ready', frmIdx, this.datas[frmIdx]);
    if (!this.datas[frmIdx] || !this.datas[frmIdx].loaded) return false;

    return true;
  }

  // draw
  updateTexture(frmIdx) {
    // console.log('[Mp4Loader] draw texture', frmIdx);
    if (this.datas[frmIdx] && this.datas[frmIdx].loaded) {
      this.player.avc.renderFrameInner({
        data: this.datas[frmIdx].textureData.data,
        width: this.datas[frmIdx].textureData.width,
        height: this.datas[frmIdx].textureData.height,
      });

      if (!this.texture) this.texture = new THREE.CanvasTexture(this.canvas);
      this.texture.needsUpdate = true;
      // this.datas[frmIdx].texture = new THREE.CanvasTexture(this.canvas);
      // this.datas[frmIdx].texture.needsUpdate = true;
    }
  }

  // decode
  decodeSEI(frmIdx, bytes, index) {
    // console.log('[Mp4Loader] decode SEI', frmIdx);

    const start = index + 16 + 8;
    const tag = bytes.slice(start + 8, start + 12);
    // console.log(tag);
    const majorVersion = HexParser.parseHexChar(
      bytes.slice(start + 12, start + 14)
    );
    const minorVersion = HexParser.parseHexChar(
      bytes.slice(start + 14, start + 16)
    );
    // console.log('version = ', majorVersion, minorVersion);
    if (majorVersion === 3 && minorVersion == 3) {
      const lengthBuf = bytes.slice(start + 32, start + 40);
      const length = HexParser.parseLengh(lengthBuf);
      // console.log('length = ', length);
      var dataBuffer = bytes.slice(start + 32 + 8, start + 32 + length);
      var base64Str = new TextDecoder("utf-8").decode(dataBuffer);
      var buffer = Base64Binary.decode(base64Str);
      this.parseDraco(frmIdx, buffer.buffer, function (msg) {
        console.log(msg);
      });
    } else {
      console.log(
        "[Mp4Loader][ERROR] undefined version ",
        majorVersion,
        minorVersion
      );
    }
  }

  parseDraco(frmIdx, buffer, onError) {
    if (!this.dracoLoader) {
      this.dracoLoader = new DRACOLoader();
      this.dracoLoader.setDecoderPath(
        "https://www.gstatic.com/draco/v1/decoders/"
      );
    }

    // decode in worker
    var taskConfig = {
      attributeIDs: this.defaultAttributeIDs,
      attributeTypes: this.defaultAttributeTypes,
      useUniqueIDs: false,
      frm_idx: frmIdx,
    };

    this.dracoLoader
      .decodeGeometry(buffer, taskConfig)
      // receive decoded mesh
      .then(
        function (geometry) {
          const frmIdx = geometry.frm_idx;
          // console.log('[Mp4MeshLoader] draco loaded ', frmIdx);
          // geometry.computeVertexNormals();
          this.datas[frmIdx].geometry = geometry;
          if (this.datas[frmIdx].checkLoaded() && this.verbose) {
            // console.log(
            "[Mp4Loader]",
              this.loaderIdx,
              "loaded",
              frmIdx,
              "(",
              frmIdx + this.mp4Info.startIdx,
              ")";
            // );
          }
        }.bind(this)
      )
      .catch(onError);
  }
}

export { Mp4Loader };
