import request from "@/utils/request";
/**
 *购买素材
 */
export const buyMaterial = async (data) => {
  return await request({
    url: "mlib/purchased",
    method: "POST",
    data,
  });
};
/**
 *获取素材详情
 *
 */
export const materialDetail = (id) => {
  return request({
    url: `/mlib/material/${id}`,
  });
};
/**
 *获取下载地址
 *
 */
export const downloadLink = (id) => {
  return request({
    url: `/mlib/material/${id}/download`,
  });
};
/**
 *获取下载列表
 *
 */
export const getDownloadList = () => {
  return request({
    url: "mlib/purchased",
  });
};
