<template>
  <span>
    <el-button
      @click.stop="confirmCollect"
      v-loading="loading"
      :disabled="loading"
      type="text"
      class="collect-btn"
      :class="item.collect ? 'el-icon-star-on' : 'el-icon-star-off'"
    ></el-button>
    <span>{{ item.star }}</span>
  </span>
</template>

<script>
import { addCollect, deleteCollect } from "@/api/collect";
import { toggle, toggleMessage } from "@/utils/common";
export default {
  name: "collect",
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async getCollect() {
      await getCollect();
    },
    login() {
      this.$emit("login");
    },
    async confirmCollect() {
      this.loading = true;
      try {
        if (this.$store.state.token) {
          if (this.item.collect) {
            await deleteCollect(this.item.id);
            toggleMessage("取消收藏", "Unfavorite");
          } else {
            await addCollect({ materialId: this.item.id });
            toggle("收藏成功", "Favorite");
          }
          this.$emit("confirmCollect");
        } else {
          this.login();
          toggleMessage("请登录", "Please login");
        }
      } catch (err) {
        // this.$message.error("操作失败重试");
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collect-btn {
  color: #ffc322;
  font-size: 18px;
  transform: translateY(1px);
}
</style>
