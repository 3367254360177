import * as THREE from "three";

class AudioManager {
  constructor(url) {
    console.log("new AudioManager triggled", url);
    this.url = url;
    this.initAudio(url);
    this.isAudioPlaying = false;
  }

  initAudio(audio_url) {
    var listener = new THREE.AudioListener();
    // camera.add( listener );
    // 创建一个非位置音频对象  用来控制播放
    var audio = new THREE.Audio(listener);
    // 创建一个音频加载器对象
    var audioLoader = new THREE.AudioLoader();
    // 加载音频文件，返回一个音频缓冲区对象作为回调函数参数
    audioLoader.load(audio_url, function (AudioBuffer) {
      // console.log(AudioBuffer)
      // 音频缓冲区对象关联到音频对象audio
      audio.setBuffer(AudioBuffer);
      audio.setLoop(true); // 是否循环
      audio.setVolume(1.0); // 音量
      // 播放缓冲区中的音频数据
      // audio.play(); //play播放、stop停止、pause暂停
    });
    this.audio = audio;
  }

  audioPlay() {
    if (!this.isAudioPlaying) {
      // console.log("audioPlay triggled");
      this.isAudioPlaying = true;
      this.audio.play();
    }
  }

  audioPause() {
    if (this.isAudioPlaying) {
      this.isAudioPlaying = false;
      this.audio.pause();
    }
  }

  audioStop() {
    this.isAudioPlaying = false;
    this.audio.stop();
  }
}

export { AudioManager };
